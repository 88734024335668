import { HeadedCard } from "../cards/HeadedCard";
import * as yup from "yup";
import { Form, Formik, FormikConfig } from "formik";
import { FormikFormField } from "../FormField";
import { useLocation, useParams } from "react-router";

type TeamProfileFormFields = {
  name: string;
  description?: string;
};

const initialValues: TeamProfileFormFields = {
  name: "",
  description: "",
};

const validationSchema = yup.object().shape({
  name: yup.string().max(100).required(),
  description: yup.string(),
});

export interface TeamProfileFormProps
  extends Omit<
    FormikConfig<TeamProfileFormFields>,
    "initialValues" | "onSubmit"
  > {}

export const TeamProfileForm = ({ ...rest }: TeamProfileFormProps) => {
  const location = useLocation();
  const { id } = useParams<{ id?: string }>();

  const createNew = id && location.pathname.startsWith("/team/create");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      {...rest}
    >
      {() => (
        <Form>
          <HeadedCard
            title={createNew ? "Create Team" : "Team Profile"}
            footer={
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Name"
                id="name"
                name="name"
                autoComplete="team-name"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Description"
                id="description"
                name="description"
                autoComplete="team-description"
              />
            </div>
          </HeadedCard>
        </Form>
      )}
    </Formik>
  );
};
