import { Formik, FormikConfig, FormikProps } from "formik";
import * as yup from "yup";
import { FormikFormField } from "../FormField";
import { AuthForm, AuthResultProps, AuthSubmitButton } from "./AuthForm";

type ForgotPasswordFields = {
  email: string;
};

const initialValues: ForgotPasswordFields = {
  email: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export interface ForgotPasswordFormProps
  extends Omit<FormikConfig<ForgotPasswordFields>, "initialValues">,
    AuthResultProps {}

export const ForgotPasswordForm = ({
  isLoading,
  isSuccess,
  ...rest
}: ForgotPasswordFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      {(props: FormikProps<ForgotPasswordFields>) => (
        <AuthForm>
          <FormikFormField
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
          />

          <AuthSubmitButton disabled={isLoading || isSuccess}>
            Send reset email
          </AuthSubmitButton>
        </AuthForm>
      )}
    </Formik>
  );
};
