import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../supabase";

export interface LoginFormFields {
  email: string;
  password: string;
}

export const useLogin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ email, password }: LoginFormFields) => {
      const { user, session, error } = await supabase.auth.signIn({
        email,
        password,
      });
      if (error) {
        throw error;
      }
      return { user, session };
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );

  return {
    mutation,
    // validationSchema,
  };
};
