import { useMutation } from "react-query";
import { supabase } from "../supabase";

export interface ForgotPasswordFields {
  email: string;
}

export const useForgotPassword = () => {
  const mutation = useMutation(async ({ email }: ForgotPasswordFields) => {
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email
    );
    if (error) {
      throw error;
    }
    return { data };
  });

  return {
    mutation,
    // validationSchema,
  };
};
