import { XCircleIcon } from "@heroicons/react/outline";
import { c8s } from "../../utils/c8s";

export interface AlertBarProps extends React.ComponentProps<"div"> {
  title: string;
  variant?: "error";
}

export const AlertBar: React.FC<AlertBarProps> = ({
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={c8s("rounded-md bg-red-50 p-4", className)} {...rest}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-yellow-700">{children}</div>
        </div>
      </div>
    </div>
  );
};
