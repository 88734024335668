import { supabase } from "@jonthompson/dailyscrum-shared";
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

export async function fetchApi(endpoint: string, init?: RequestInit) {
  const headers = init?.headers ? new Headers(init.headers) : new Headers();
  const authBearer = supabase.auth.session()?.access_token ?? SUPABASE_KEY;
  headers.set("apiKey", SUPABASE_KEY);
  headers.set("Authorization", `Bearer ${authBearer}`);
  headers.set("Content-Type", "application/json");
  return fetch(`${process.env.REACT_APP_API_BASE}${endpoint}`, {
    headers,
  }).then(async (response) => await response.json());
}
