import { supabase } from "@jonthompson/dailyscrum-shared";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifications } from "../components/Notifications";

export interface DailyEntryT {
  id: string;
  user_id: string;
  team_id?: string;
  date: string;
  draft?: boolean;
}

export const useDailyEntry = ({
  user_id,
  team_id,
  date,
}: Omit<DailyEntryT, "id" | "user_id"> &
  Partial<Pick<DailyEntryT, "user_id">>) => {
  const query = useQuery<{}, Error, DailyEntryT>(
    ["dailyEntry", user_id, team_id, date],
    async () => {
      let query = supabase
        .from("daily_entries, users")
        .select(
          `
        id,
        date,
        users:display_name
        `
        )
        .eq("user_id", user_id)
        .eq("date", date);

      if (team_id) {
        query.eq("team_id", team_id);
      }

      const { data, error } = await query;
      if (error) {
        throw error;
      }
      return data?.[0];
    }
  );

  return query;
};

export const useCreateDailyEntry = () => {
  const queryClient = useQueryClient();
  const notifications = useNotifications();

  const mutation = useMutation<DailyEntryT, Error, Omit<DailyEntryT, "id">>(
    async ({ team_id, date }) => {
      const { data, error } = await supabase
        .from("daily_entries")
        .insert({
          team_id,
          date,
        })
        .limit(1)
        .single();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["dailyEntry", result.user_id, result.team_id, result.date],
          () => {
            return result;
          }
        );
      },
      onError: (e) => {
        notifications.addNotification({
          type: "failed",
          title: "Failed to create",
          description: e.message || e.toString(),
        });
      },
    }
  );

  return mutation;
};

export const useUpdateDailyEntry = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<DailyEntryT, Error, DailyEntryT>(
    async ({ id, team_id, date }) => {
      const { data, error } = await supabase
        .from("daily_entries")
        .update({
          date,
          team_id,
        })
        .eq("id", id)
        .limit(1)
        .single();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["dailyEntry", result.user_id, result.team_id, result.date],
          () => {
            return result;
          }
        );
      },
    }
  );

  return mutation;
};

export const useDeleteDailyEntry = () => {
  const queryClient = useQueryClient();
  const notifications = useNotifications();

  const mutation = useMutation<DailyEntryT, Error, Pick<DailyEntryT, "id">>(
    async ({ id }) => {
      const { data, error } = await supabase
        .from("daily_entries")
        .delete({ returning: "representation" })
        .eq("id", id);
      if (error) {
        throw error;
      }
      return data?.[0];
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["dailyEntry", result.user_id, result.team_id, result.date],
          () => {
            return undefined;
          }
        );
      },
      onError: (err) => {
        notifications.addNotification({
          type: "failed",
          title: "Failed to delete item",
          description: err.message || err.toString(),
        });
      },
    }
  );

  return mutation;
};
