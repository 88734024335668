import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLogin } from "@jonthompson/dailyscrum-shared";
import { AuthPage } from "./AuthPage";
import { LoginForm } from "../forms/LoginForm";
import { AlertBar } from "../AlertBar";

export const LoginPage = () => {
  const { mutation } = useLogin();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          const referer = (history.location?.state as { referer?: string })
            ?.referer;
          if (referer) {
            const url = new URL(
              `${window.location.protocol}//${window.location.host}${referer}`
            );
            history.push(url.pathname);
          } else {
            history.push("/");
          }
        },
      });
    },
    [history, mutation]
  );

  return (
    <AuthPage title="Log in to your account">
      {mutation.isError && (
        <AlertBar title="Login failed" variant="error" className="mb-4">
          {(mutation.error as Error).message || ""}
        </AlertBar>
      )}
      <LoginForm
        isLoading={mutation.isLoading}
        isSuccess={mutation.isSuccess}
        onSubmit={handleSubmit}
      />
      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or</span>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1">
          <div>
            <Link
              to="/signup"
              className="btn-primary w-full flex justify-center"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </AuthPage>
  );
};
