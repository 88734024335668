import { Form } from "formik";

export const AuthForm: React.FC = (props) => {
  return <Form className="space-y-6" {...props} />;
};

export interface AuthResultProps {
  isLoading?: boolean;
  isSuccess?: boolean;
}

export interface AuthSubmitButtonProps
  extends React.ComponentProps<"button">,
    AuthResultProps {}

export const AuthSubmitButton: React.FC<AuthSubmitButtonProps> = ({
  isLoading,
  isSuccess,
  ...rest
}) => {
  return (
    <div>
      <button
        type="submit"
        className="btn-primary w-full flex justify-center"
        disabled={isLoading || isSuccess}
        {...rest}
      />
    </div>
  );
};
