import {
  CogIcon,
  CreditCardIcon,
  UserCircleIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { TeamProfileForm } from "../forms/TeamProfileForm";
import { PageWithSubNav } from "./PageWithSubNav";

const subNavigation = [
  {
    name: "Team Profile",
    path: "/team/profile",
    icon: UserCircleIcon,
    component: TeamProfileForm,
  },
  {
    name: "Team Settings",
    path: "/team/settings",
    icon: CogIcon,
    component: TeamProfileForm,
  },
  {
    name: "Team Members",
    path: "/team/change-email",
    icon: MailIcon,
    component: TeamProfileForm,
  },
  {
    name: "Delete team",
    path: "/team/delete",
    icon: CreditCardIcon,
    component: TeamProfileForm,
  },
];

export const TeamSettingsPage = () => {
  return <PageWithSubNav routes={subNavigation} />;
};
