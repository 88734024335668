import { useSupabase } from "@jonthompson/dailyscrum-shared";
import { User } from "@supabase/gotrue-js";
import { Form, Formik, FormikConfig } from "formik";
import { useCallback } from "react";
import { useMutation } from "react-query";
import * as yup from "yup";
import { HeadedCard } from "../cards/HeadedCard";
import { FormikFormField } from "../FormField";
import { useNotifications } from "../Notifications";

type ChangePasswordFormFields = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
};

const initialValues: ChangePasswordFormFields = {
  current_password: "",
  new_password: "",
  confirm_new_password: "",
};

const validationSchema = yup.object().shape({
  current_password: yup.string().min(6).required(),
  new_password: yup.string().min(6).required(),
  confirm_new_password: yup.string().min(6).required(),
});

export const useChangePasswordMutation = () => {
  const { supabase } = useSupabase();
  const mutation = useMutation<
    {
      data: User | null;
    },
    Error,
    ChangePasswordFormFields
  >(
    async ({
      current_password,
      new_password,
      confirm_new_password,
    }: ChangePasswordFormFields) => {
      if (new_password !== confirm_new_password) {
        throw new Error("New passwords do not match");
      }
      const { error: authError } = await supabase.auth.signIn({
        email: supabase.auth.user()?.email,
        password: current_password,
      });
      if (authError) {
        throw new Error(
          "Current password is incorrect. You have been logged out for security."
        );
      }
      const { data, error } = await supabase.auth.update({
        password: new_password,
      });
      if (error) {
        await supabase.auth.signOut();
        throw new Error("Error saving new password");
      }

      return { data };
    }
  );

  return {
    mutation,
  };
};

export interface ChangePasswordFormProps
  extends Omit<
    FormikConfig<ChangePasswordFormFields>,
    "initialValues" | "onSubmit"
  > {}

export const ChangePasswordForm = ({ ...rest }: ChangePasswordFormProps) => {
  const { mutation } = useChangePasswordMutation();
  const notifications = useNotifications();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          notifications.addNotification({
            title: "Success",
            description: "Password changed",
            type: "success",
          });
        },
        onError: (err) => {
          notifications.addNotification({
            title: "Failed",
            description: err.message || err.toString(),
            type: "failed",
          });
          if (
            err.message ===
            "Current password is incorrect. You have been logged out for security."
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        },
      });
    },
    [mutation, notifications]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {() => (
        <Form>
          <HeadedCard
            title="Change your password"
            description="Update your billing information. Please note that updating your
                location could affect your tax rates."
            footer={
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="col-span-full">
              <FormikFormField
                label="Current password"
                id="current_password"
                name="current_password"
                autoComplete="current-password"
                type="password"
                required
              />
            </div>

            <div className="col-span-full">
              <FormikFormField
                label="New password"
                id="new_password"
                name="new_password"
                autoComplete="new-password"
                type="password"
                required
              />
            </div>

            <div className="col-span-full">
              <FormikFormField
                label="Confirm new password"
                id="confirm_new_password"
                name="confirm_new_password"
                autoComplete="new-password"
                type="password"
                required
              />
            </div>
          </HeadedCard>
        </Form>
      )}
    </Formik>
  );
};
