import { useCallback } from "react";
import { useMutation } from "react-query";
import { supabase } from "../supabase";

export interface WaitingListFormFields {
  email: string;
}

export const useWaitingList = () => {
  const mutation = useMutation(async ({ email }: WaitingListFormFields) => {
    const { data, error } = await supabase
      .from("waiting_list")
      .insert([{ email }], { returning: "minimal" });

    if (error) {
      throw error;
    }
    return { data };
  });

  const handleSubmit = useCallback(
    (values: WaitingListFormFields) => {
      mutation.mutate(values);
    },
    [mutation.mutate]
  );

  return {
    handleSubmit,
    mutation,
    // validationSchema,
  };
};
