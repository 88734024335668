export const Footer = () => {
  return (
    <footer>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div className="px-5 py-2">
            <a
              href={`mailto:contact@${process.env.REACT_APP_DOMAIN}`}
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Contact
            </a>
          </div>
        </nav>
        {/* <div className="mt-8 flex justify-center space-x-6">
        {social.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">{item.name}</span>
            <item.icon className="h-6 w-6" aria-hidden="true" />
          </a>
        ))}
      </div> */}
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2021 {process.env.REACT_APP_NAME}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
