import { useSupabase } from "@jonthompson/dailyscrum-shared";
import { User } from "@supabase/gotrue-js";
import { Form, Formik, FormikConfig } from "formik";
import { useCallback } from "react";
import { useMutation } from "react-query";
import * as yup from "yup";
import { HeadedCard } from "../cards/HeadedCard";
import { FormikFormField } from "../FormField";
import { useNotifications } from "../Notifications";

type ChangeEmailFormFields = {
  email: string;
  password: string;
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export const useChangeEmailMutation = () => {
  const { supabase, user } = useSupabase();
  const mutation = useMutation<
    {
      data: User | null;
    },
    Error,
    ChangeEmailFormFields
  >(async ({ email, password }: ChangeEmailFormFields) => {
    const currentEmail = user?.email;
    if (currentEmail && currentEmail !== email) {
      throw new Error("That is already your email address");
    }
    const { error: authError } = await supabase.auth.signIn({
      email: user?.email,
      password,
    });
    if (authError) {
      throw new Error(
        "Password is incorrect. You have been logged out for security."
      );
    }
    const { data, error } = await supabase.auth.update({
      email,
    });
    if (error) {
      await supabase.auth.signOut();
      throw new Error("Error updating email");
    }

    return { data };
  });

  return {
    mutation,
  };
};

export interface ChangeEmailFormProps
  extends Omit<
    FormikConfig<ChangeEmailFormFields>,
    "initialValues" | "onSubmit"
  > {}

export const ChangeEmailForm = ({ ...rest }: ChangeEmailFormProps) => {
  const { mutation } = useChangeEmailMutation();
  const notifications = useNotifications();

  const { user } = useSupabase();

  const initialValues: ChangeEmailFormFields = {
    email: user?.email ?? "",
    password: "",
  };

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          notifications.addNotification({
            title: "Success",
            description: "Email changed",
            type: "success",
          });
        },
        onError: (err) => {
          notifications.addNotification({
            title: "Failed",
            description: err.message || err.toString(),
            type: "failed",
          });
          if (
            err.message ===
            "Password is incorrect. You have been logged out for security."
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        },
      });
    },
    [mutation, notifications]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {() => (
        <Form>
          <HeadedCard
            title="Change your email"
            description="Update your billing information. Please note that updating your
                location could affect your tax rates."
            footer={
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="col-span-full">
              <FormikFormField
                label="Email address"
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                required
              />
            </div>

            <div className="col-span-full">
              <FormikFormField
                label="Password"
                id="password"
                name="password"
                autoComplete="current-password"
                type="password"
                required
              />
            </div>
          </HeadedCard>
        </Form>
      )}
    </Formik>
  );
};
