import { Field, FieldProps } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { c8s } from "../../utils/c8s";
import { Input, InputProps } from "../Input";

export interface FormFieldProps extends InputProps {
  label?: string;
  description?: string;
  error?: string | false;
}

export const FormField = ({
  label,
  description,
  error,
  ...rest
}: FormFieldProps) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={rest.id}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className={c8s("relative rounded-md shadow-sm", label && "mt-1")}>
        <Input
          hasError={!!error}
          aria-describedby={
            error || description
              ? `${rest.id}-${error ? "error" : "description"}`
              : undefined
          }
          {...rest}
        />
        {error && (
          <div className="absolute inset-y-0 -right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${rest.id}-error`}>
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500" id={`${rest.id}-description`}>
          {description}
        </p>
      )}
    </div>
  );
};

export const FormikFormField = ({
  name,
  ...rest
}: Omit<FormFieldProps, "error"> & { name: string }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<any>) => (
        <FormField {...field} {...rest} error={meta.touched && meta.error} />
      )}
    </Field>
  );
};
