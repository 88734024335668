/* This example requires Tailwind CSS v2.0+ */

import { ComponentType, useState } from "react";
import { CreateOrgForm } from "../forms/CreateOrgForm";

type StepsT = "createOrg" | "createTeam" | "inviteMembers";

const stepComponents: { [k in StepsT]: ComponentType } = {
  createOrg: CreateOrgForm,
  createTeam: CreateOrgForm,
  inviteMembers: CreateOrgForm,
};

export const SetupPage = () => {
  const [step] = useState<StepsT>(() => "createOrg");

  const StepComponent = stepComponents[step];

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-3xl lg:px-8">
          <StepComponent />
        </div>
      </div>
    </div>
  );
};
