import { Menu } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useUser } from "@jonthompson/dailyscrum-shared";
import { useRef } from "react";
import {
  DailyEntryT,
  useCreateDailyEntry,
  useDailyEntry,
  useDeleteDailyEntry,
} from "../../hooks/useDailyEntry";
import {
  useCreateEntryItem,
  useDeleteEntryItem,
  useEntryItems,
  useUpdateEntryItem,
} from "../../hooks/useEntryItem";
import { c8s } from "../../utils/c8s";
import { HeadedCard } from "../cards/HeadedCard";
import { Input } from "../Input";
import { useNotifications } from "../Notifications";

export type EntryItemT = {
  id: string;
  daily_entry_id: string;
  user_id: string;
  team_id?: string;
  type: "yesterday" | "today" | "blockers";
  content: string;
};

export interface EntryItemProps extends EntryItemT {
  editable?: boolean;
}

const EntryItem = ({
  id,
  daily_entry_id,
  type,
  content,
  editable = true,
}: EntryItemProps) => {
  const mutation = useUpdateEntryItem();
  const deleteMutation = useDeleteEntryItem();

  const { data: user } = useUser();
  if (!user) {
    throw new Error("No user but there should be");
  }
  const notifications = useNotifications();
  return (
    <Input
      type="text"
      className="w-full mb-2"
      disabled={!editable}
      readOnly={!editable}
      onBlur={async (e) => {
        if (e.target.value === "") {
          deleteMutation.mutate({
            id,
          });
        } else if (e.target.value !== content) {
          mutation.mutate(
            {
              id,
              content: e.target.value,
              user_id: user.id,
              daily_entry_id,
              type,
            },
            {
              onSuccess: () => {
                notifications.addNotification({
                  type: "success",
                  title: "Changes saved",
                });
              },
              onError: () => {
                notifications.addNotification({
                  type: "failed",
                  title: "Failed to save changes",
                });
              },
            }
          );
        }
      }}
      defaultValue={content}
    />
  );
};

const NewEntryItem = ({
  daily_entry_id,
  type,
}: Omit<EntryItemT, "content" | "id" | "user_id">) => {
  const { data: user } = useUser();
  if (!user) {
    throw new Error("No user but there should be");
  }
  const mutation = useCreateEntryItem();
  const notifications = useNotifications();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Input
      type="text"
      className="w-full"
      ref={inputRef}
      onBlur={async (e) => {
        if (!!e.target.value) {
          mutation.mutate(
            {
              daily_entry_id,
              type,
              content: e.target.value,
              user_id: user?.id,
            },
            {
              onSuccess: () => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              },
              onError: (err) => {
                notifications.addNotification({
                  type: "failed",
                  title: "Failed to add item",
                  description: err.message || err.toString(),
                });
              },
            }
          );
        }
      }}
      placeholder="Add new item..."
    />
  );
};

export interface CreateDailyEntryProps {
  date: string;
}

export const CreateDailyEntry = ({ date }: CreateDailyEntryProps) => {
  const mutation = useCreateDailyEntry();
  const { data: user } = useUser();
  if (!user) {
    throw new Error("No user but there should be");
  }
  return (
    <div className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg mb-4">
      <button
        onClick={() => {
          mutation.mutate({ date, user_id: user.id });
        }}
      >
        Add your entry
      </button>
    </div>
  );
};

export interface DailyEntryProps {
  date: string;
  editable?: boolean;
}

export const DailyEntry = ({ date, editable = true }: DailyEntryProps) => {
  const { data: user } = useUser();
  if (!user) {
    throw new Error("No user but there should be");
  }
  const { data: dailyEntry, isLoading } = useDailyEntry({
    date,
    user_id: user.id,
  });

  const { data: yesterday } = useEntryItems(
    { daily_entry_id: (dailyEntry as DailyEntryT)?.id, type: "yesterday" },
    !!dailyEntry?.id
  );
  const { data: today } = useEntryItems(
    { daily_entry_id: (dailyEntry as DailyEntryT)?.id, type: "today" },
    !!dailyEntry?.id
  );
  const { data: blockers } = useEntryItems(
    { daily_entry_id: (dailyEntry as DailyEntryT)?.id, type: "blockers" },
    !!dailyEntry?.id
  );

  const deleteMutation = useDeleteDailyEntry();

  if (isLoading) {
    return null;
  }

  if (!dailyEntry) {
    return <CreateDailyEntry date={date} />;
  }

  const friendlyDate = new Date(Date.parse(date)).toLocaleDateString(
    undefined,
    {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  const title = dailyEntry.draft ? `${friendlyDate} - DRAFT` : friendlyDate;

  return (
    <HeadedCard
      title={title}
      className="mb-4"
      actionMenuItems={[
        <Menu.Item key="delete">
          {({ active }) => (
            <button
              disabled={!editable}
              title={editable ? "Delete" : "Can not be deleted"}
              onClick={() => {
                deleteMutation.mutate({
                  id: dailyEntry.id,
                });
              }}
              className={c8s(
                active && editable
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-700",
                !editable && "cursor-not-allowed",
                "flex px-4 py-2 text-sm w-full"
              )}
            >
              <ExclamationIcon
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Delete</span>
            </button>
          )}
        </Menu.Item>,
      ]}
      footer={
        <div className="text-right">
          <button
            type="submit"
            className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mr-2"
          >
            Save draft
          </button>
          <button className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
            Post
          </button>
        </div>
      }
    >
      <div className="col-span-full -mt-4">
        <h4 className="mb-2">Yesterday</h4>
        <ul>
          {yesterday?.map((y) => {
            return (
              <li key={y.id}>
                <EntryItem key={y.id} editable={editable} {...y} />
              </li>
            );
          })}
          {editable && (
            <li>
              <NewEntryItem daily_entry_id={dailyEntry.id} type="yesterday" />
            </li>
          )}
        </ul>
        <h4 className="mt-4 mb-2">Today</h4>
        <ul>
          {today?.map((y) => {
            return (
              <li key={y.id}>
                <EntryItem key={y.id} editable={editable} {...y} />
              </li>
            );
          })}
          {editable && (
            <li>
              <NewEntryItem daily_entry_id={dailyEntry.id} type="today" />
            </li>
          )}
        </ul>
        <h4 className="mt-4 mb-2">Blockers</h4>
        <ul>
          {blockers?.map((y) => {
            return (
              <li key={y.id}>
                <EntryItem key={y.id} editable={editable} {...y} />
              </li>
            );
          })}
          {editable && (
            <li>
              <NewEntryItem daily_entry_id={dailyEntry.id} type="blockers" />
            </li>
          )}
        </ul>
      </div>
    </HeadedCard>
  );
};
