import { supabase, useUser } from "@jonthompson/dailyscrum-shared";
import { useQuery } from "react-query";
import { TeamSchema } from "../validationSchemas/teamSchema";
import {
  useCreateByIdMutation,
  useSelectByMatch,
  useUpdateByIdMutation,
} from "./createUseMutations";

const TABLE_NAME = "teams";

export const useCreateTeam = () => {
  return useCreateByIdMutation<TeamSchema, Omit<TeamSchema, "id">>(TABLE_NAME);
};

export const useUpdateTeam = () =>
  useUpdateByIdMutation<TeamSchema, TeamSchema>(TABLE_NAME);

export const useTeam = (id?: string) => {
  const { data: user } = useUser();

  const query = useQuery<TeamSchema, Error, TeamSchema>(
    ["team", id],
    async () => {
      const { data, error } = await supabase
        .from("teams, team_users")
        .select(
          `teams.id,
          teams.name,
          teams.description,
          team_users.role`
        )
        .match(
          id && user?.id
            ? {
                "teams.id": id,
                "team_users.user_id": user?.id,
                "team_users.team_id": id,
              }
            : {}
        )
        .limit(1)
        .single();
      if (error) {
        throw error;
      }
      if (!data) {
        throw new Error("Team not found");
      }
      return data;
    },
    { enabled: !!id && !!user }
  );
  return query;
};

export const useOrgTeams = (orgId?: string) => {
  const { data: user } = useUser();

  const query = useQuery<TeamSchema[], Error, TeamSchema[]>(
    ["organization_teams", orgId],
    async () => {
      const { data, error } = await supabase
        .from("teams, organization_users")
        .select(
          `teams.name,
          teams.description,
          organization_users.role`
        )
        .match(
          orgId && user?.id
            ? {
                "teams.organization_id": orgId,
              }
            : {}
        );
      if (error) {
        throw error;
      }
      if (!data) {
        return [];
      }
      return data as TeamSchema[];
    },
    { enabled: !!orgId && !!user }
  );
  return query;
};

export const useTeamUsers = (id?: string) => {
  const { data: user } = useUser();

  const query = useQuery<TeamSchema[], Error, TeamSchema>(
    ["team_users", id],
    async () => {
      const { data, error } = await supabase
        .from("team_users, user_profiles")
        .select(
          `
          user_profiles.id,
          user_profiles.display_name,
          user_profiles.full_name,
          user_profiles.avatar_url,
        team_users.role

    `
        )
        .match(
          id && user?.id
            ? {
                "user_profiles.id": user?.id,
                "team_users.user_id": user?.id,
                "team_users.team_id": id,
              }
            : {}
        );
      if (error) {
        throw error;
      }
      if (!data) {
        return [];
      }
      return data;
    },
    { enabled: !!id && !!user }
  );
  return query;
};

export const useSelectUsersTeams = (userId: string) => {
  return useSelectByMatch<TeamSchema>("team_users", { user_id: userId });
};

export const useInviteTeamUser = () => {};

export const useRemoveTeamUser = () => {};

export const useEditTeamUserRole = () => {};

// export const useSelectTeamEntries = ({ day, team_id }) => {};
