import { supabase } from "@jonthompson/dailyscrum-shared";
import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";
import { useOnErrorHandler } from "./useOnErrorNotification";

export function useCreateSingleByIdMutation<
  TData extends { id: string } = { id: string },
  TVariables extends {} = {}
>(
  tableName: string,
  queryKey: QueryKey | ((variables: TVariables, data: TData) => QueryKey) = (
    variables,
    data
  ) => [tableName, data.id]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<TData, Error, TVariables>(
    async (values) => {
      const { data, error } = await supabase
        .from(tableName)
        .insert(values)
        .single();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<TData>(_queryKey, (cache) => {
          return data;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useCreateByIdMutation<
  TData extends { id: string } = { id: string },
  TVariables extends {} = {}
>(
  tableName: string,
  queryKey: QueryKey | ((variables: TVariables, data: TData[]) => QueryKey) = (
    variables,
    data
  ) => [tableName]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<TData[], Error, TVariables>(
    async (values) => {
      const { data, error } = await supabase.from(tableName).insert(values);
      if (error) {
        throw error;
      }
      if (!data || !data.length) {
        throw new Error("Nothing was created!");
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<TData[]>(_queryKey, (cache) => {
          if (!cache) {
            return data;
          }
          let _cache = cache;

          for (let index = 0; index < data.length; index++) {
            const cacheIndex = _cache.findIndex((c) => c.id === data[index].id);
            if (cacheIndex) {
              throw new Error("Created item is already in the cache!");
            } else {
              _cache.push(data[index]);
            }
          }

          return _cache;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useUpdateSingleByIdMutation<
  TData extends { id: string } = { id: string },
  TVariables extends { id: string } = { id: string }
>(
  tableName: string,
  queryKey: QueryKey | ((variables: TVariables, data: TData) => QueryKey) = (
    variables,
    data
  ) => [tableName]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<TData, Error, TVariables>(
    async ({ id, ...values }) => {
      const { data, error } = await supabase
        .from(tableName)
        .update(values)
        .eq("id", id)
        .single();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<TData>(_queryKey, (cache) => {
          return data;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useUpdateByIdMutation<
  TData extends { id: string } = { id: string },
  TVariables extends { id: string } = { id: string }
>(
  tableName: string,
  queryKey: QueryKey | ((variables: TVariables, data: TData[]) => QueryKey) = (
    variables,
    data
  ) => [tableName, variables.id]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<TData[], Error, TVariables>(
    async ({ id, ...values }) => {
      const { data, error } = await supabase
        .from(tableName)
        .update(values)
        .eq("id", id);
      if (error) {
        throw error;
      }
      if (!data || !data.length) {
        throw new Error("Nothing was updated!");
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<TData[] | null>(_queryKey, (cache) => {
          if (!cache) {
            return data;
          }
          let _cache = cache;
          data.forEach((updatedItem) => {
            const cacheIndex = _cache.findIndex((c) => c.id === updatedItem.id);
            if (cacheIndex) {
              _cache[cacheIndex] = { ..._cache[cacheIndex], ...updatedItem };
            } else {
              _cache.push(updatedItem);
            }
          });

          return _cache;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useDeleteSingleByIdMutation<
  // TData extends { id: string } = { id: string },
  TVariables extends { id: string } = { id: string }
>(
  tableName: string,
  queryKey: QueryKey | ((variables: TVariables, data: null) => QueryKey) = (
    variables
  ) => [tableName, variables.id]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<null, Error, TVariables>(
    async ({ id }) => {
      const { data, error } = await supabase
        .from(tableName)
        .delete()
        .eq("id", id)
        .single();
      if (error) {
        throw error;
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<null>(_queryKey, (cache) => {
          return null;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useDeleteByIdMutation<
  TData extends { id: string } = { id: string },
  TVariables extends { id: string } = { id: string }
>(
  tableName: string,
  queryKey:
    | QueryKey
    | ((variables: TVariables, data: TData[] | null) => QueryKey) = (
    variables
  ) => [tableName]
) {
  const queryClient = useQueryClient();

  const handleOnError = useOnErrorHandler();

  const mutation = useMutation<TData[], Error, TVariables>(
    async ({ id }) => {
      const { data, error } = await supabase
        .from(tableName)
        .delete()
        .eq("id", id);
      if (error) {
        throw error;
      }
      if (!data) {
        throw new Error("Nothing was deleted!");
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        const _queryKey =
          typeof queryKey === "function" ? queryKey(variables, data) : queryKey;

        queryClient.setQueryData<TData[]>(_queryKey, (cache) => {
          if (!cache) {
            return [];
          }
          let _cache = cache;
          const cacheIndex = _cache.findIndex((c) => c.id === variables.id);
          if (cacheIndex) {
            delete _cache[cacheIndex];
          }
          return _cache;
        });
      },
      onError: handleOnError,
    }
  );

  return mutation;
}

export function useSelectById<TQueryFnData = unknown, TData = TQueryFnData>(
  tableName: string,
  id?: string | false | null | undefined
) {
  const query = useQuery<TQueryFnData[], Error, TData[]>(
    [tableName],
    async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select("*")
        .eq("id", id);
      if (error) {
        throw error;
      }
      return data as TQueryFnData[];
    },
    { enabled: !!id }
  );
  return query;
}

export function useSelectSingleById<
  TQueryFnData = unknown,
  TData = TQueryFnData
>(tableName: string, id?: string | false | null | undefined) {
  const query = useQuery<TQueryFnData, Error, TData>(
    [tableName, id],
    async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select("*")
        .eq("id", id)
        .single();
      if (error) {
        throw error;
      }
      return data as TQueryFnData;
    },
    { enabled: !!id }
  );
  return query;
}

export function useSelectByMatch<TQueryFnData = unknown, TData = TQueryFnData>(
  tableName: string,
  match?: { [key: string]: string } | false | null | undefined
) {
  const query = useQuery<TQueryFnData[], Error, TData[]>(
    [tableName],
    async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select("*")
        .match(match || {});
      if (error) {
        throw error;
      }
      return data as TQueryFnData[];
    },
    { enabled: !!match }
  );
  return query;
}

export function useSelectSingleByMatch<
  TQueryFnData = unknown,
  TData = TQueryFnData
>({
  queryKey,
  match,
  from,
  select = "*",
}: {
  queryKey: QueryKey;
  match?: { [key: string]: any } | false | null | undefined;
  from: string;
  select: string;
}) {
  const query = useQuery<TQueryFnData, Error, TData>(
    queryKey,
    async () => {
      const { data, error } = await supabase
        .from(from)
        .select(select)
        .match(match || {})
        .limit(1)
        .single();
      if (error) {
        throw error;
      }
      return data as TQueryFnData;
    },
    { enabled: !!match }
  );
  return query;
}
