import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { c8s } from "../../utils/c8s";

export type NotificationProps = {
  title: string;
  description?: string;
  type?: "success" | "failed";
  id: number;
  closeNotification: (id: number) => void;
};

const icons = {
  success: CheckCircleIcon,
  failed: ExclamationCircleIcon,
};

const colors = {
  success: "text-green-400",
  failed: "text-red-400",
};

export const Notification = ({
  title,
  description,
  type,
  id,
  closeNotification,
}: NotificationProps) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      closeNotification(id);
    }, 100);
  };
  const Icon = type && icons[type];
  return (
    <>
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-2">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {Icon && (
                  <Icon
                    className={c8s("h-6 w-6", type && colors[type])}
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">{title}</p>
                <p className="mt-1 text-sm text-gray-500">{description}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
