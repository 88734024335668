import { HeadedCard } from "../cards/HeadedCard";
import * as yup from "yup";
import { Form, Formik, FormikConfig } from "formik";
import { FormikFormField } from "../FormField";
import { useSupabase, useUser } from "@jonthompson/dailyscrum-shared";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { useNotifications } from "../Notifications";

type UserProfileFormFields = {
  display_name?: string;
  full_name?: string;
  job_title?: string;
  avatar_url?: string;
};

const validationSchema = yup.object().shape({
  display_name: yup.string().max(100).nullable(),
  full_name: yup.string().max(100).nullable(),
  job_title: yup.string().max(100).nullable(),
  avatar_url: yup.string().nullable(),
});

export const useChangeProfileMutation = () => {
  const { supabase } = useSupabase();
  const { data: user } = useUser();

  const initialValues: UserProfileFormFields = {
    display_name: "",
    full_name: "",
    job_title: "",
    avatar_url: "",
  };

  const queryClient = useQueryClient();

  const mutation = useMutation<any, Error, UserProfileFormFields>(
    async ({ ...rest }: UserProfileFormFields) => {
      const { data, error } = await supabase
        .from("user_profiles")
        .update(rest)
        .eq("id", user?.id)
        .limit(1)
        .single();
      if (error) {
        throw new Error("Failed to update additional details");
      }
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("user", { ...user, details: data });
      },
    }
  );

  return {
    mutation,
    initialValues,
  };
};

export interface UserProfileFormProps
  extends Omit<
    FormikConfig<UserProfileFormFields>,
    "initialValues" | "onSubmit"
  > {}

export const UserProfileForm = ({ ...rest }: UserProfileFormProps) => {
  const { mutation, initialValues } = useChangeProfileMutation();
  const notifications = useNotifications();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          notifications.addNotification({
            title: "Changes saved!",
            type: "success",
          });
        },
        onError: (err) => {
          notifications.addNotification({
            title: "Save failed",
            description: err.message || err.toString(),
            type: "failed",
          });
        },
      });
    },
    [mutation, notifications]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      {...rest}
    >
      {() => (
        <Form>
          <HeadedCard
            title="Your Profile"
            description="Update your billing information. Please note that updating your location could affect your tax rates."
            footer={
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Display name"
                id="display_name"
                name="display_name"
                autoComplete="display-name"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Full name"
                id="full_name"
                name="full_name"
                autoComplete="full-name"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Job title"
                id="job_title"
                name="job_title"
                autoComplete="job-title"
              />
            </div>
          </HeadedCard>
        </Form>
      )}
    </Formik>
  );
};
