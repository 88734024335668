/* This example requires Tailwind CSS v2.0+ */
import { useUser } from "@jonthompson/dailyscrum-shared";
import { useOrgTeams } from "../../hooks/useTeam";
import { TeamCard } from "../cards/TeamCard";

export const TeamsGrid = () => {};

export const TeamsPage = () => {
  const { data: user } = useUser();
  const { data: orgTeams } = useOrgTeams(user?.org?.id);
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
          <h3>Your Teams</h3>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {user?.teams &&
              user?.teams?.map(({ id }) => (
                <TeamCard key={id} id={id} as="li" />
              ))}
          </ul>
          <h3>Organization Teams</h3>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {orgTeams &&
              orgTeams.map(({ id }) => <TeamCard key={id} id={id} as="li" />)}
          </ul>
        </div>
      </div>
    </div>
  );
};
