import { Fragment, useCallback, useState } from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

export interface DialogProps {
  title: string;
  description?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isClosable?: boolean;
  onClose?: () => void;
  actions?: React.ReactNode;
}

export interface useDialogArgs {
  defaultOpen: boolean;
}

export const useDialog = (
  { defaultOpen }: useDialogArgs = { defaultOpen: false }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  return { isOpen, setIsOpen };
};

export const Dialog: React.FC<DialogProps> = ({
  title,
  description,
  children,
  isOpen,
  setIsOpen,
  onClose,
  isClosable,
  actions,
}) => {
  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    if (isClosable) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isClosable, onClose, setIsOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={handleOnClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <HeadlessDialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </HeadlessDialog.Title>
                  {description && (
                    <HeadlessDialog.Description className="text-sm text-gray-500 mt-2">
                      {description}
                    </HeadlessDialog.Description>
                  )}
                  {children && <div className="mt-2">{children}</div>}
                </div>
              </div>
              <div className="mt-5 sm:mt-6">{actions}</div>
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};
