import { SupabaseClient, User, Session } from "@supabase/supabase-js";
import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../supabase";

type UserDetails = {
  display_name?: string;
  full_name?: string;
  job_title?: string;
  company?: string;
  avatar_url?: string;
};

export const SupabaseContext = React.createContext<{
  supabase: SupabaseClient;
  user?: (User & { details?: UserDetails }) | null;
  session?: Session | null;
}>({ supabase, user: supabase.auth.user(), session: supabase.auth.session() });

export const useSupabase = () => useContext(SupabaseContext);

export const SupabaseProvider: React.FC = ({ children }) => {
  const [sessionState, setSessionState] = useState<null | Session>();
  const [userState, setUserState] = useState<
    null | (User & { details?: UserDetails })
  >(supabase.auth.user());

  useEffect(() => {
    const currentSession = supabase.auth.session();
    if (currentSession) {
      supabase
        .from("users")
        .select("*")
        .eq("id", currentSession.user.id)
        .then(details => {
          setSessionState(currentSession);
          setUserState({
            ...currentSession.user,
            details: details?.data?.[0] as UserDetails,
          });
        });
    }

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "SIGNED_OUT") {
        setSessionState(null);
        setUserState(null);
        return;
      }
      if (session && session.user) {
        const { data, error } = await supabase
          .from("users")
          .select("*")
          .eq("id", session.user.id)
          .limit(1);
        setSessionState(session);
        if (error) {
          throw new Error("Could not find user details");
        }
        setUserState({ ...session.user, details: data?.[0] });
      } else {
        setSessionState(null);
        setUserState(null);
      }
    });
  }, []);

  console.log({ userState, sessionState });

  return (
    <SupabaseContext.Provider
      value={{ supabase, session: sessionState, user: userState }}
    >
      {children}
    </SupabaseContext.Provider>
  );
};
