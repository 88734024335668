import { HeadedCard } from "../cards/HeadedCard";
import { Form, Formik, FormikConfig } from "formik";
import { FormikFormField } from "../FormField";
import { useHistory } from "react-router";
import {
  OrganizationSchema,
  organizationValidationSchema,
} from "../../validationSchemas/orgSchema";
import { useCreateOrg, useOrg, useUpdateOrg } from "../../hooks/useOrg";
import { useUser } from "@jonthompson/dailyscrum-shared";

const initialValues: Omit<OrganizationSchema, "id"> = {
  name: "",
  description: "",
};

export interface OrganizationProfileFormProps
  extends Omit<
    FormikConfig<Omit<OrganizationSchema, "id">>,
    "initialValues" | "onSubmit"
  > {}

export const CreateOrgForm = ({ ...rest }: OrganizationProfileFormProps) => {
  const history = useHistory();
  const { data: user } = useUser();
  const orgId = user?.org?.id;

  const createMutation = useCreateOrg();
  const updateMutation = useUpdateOrg();

  const { data: org } = useOrg(orgId);

  console.log({ orgId, org });

  return (
    <Formik
      initialValues={org || initialValues}
      enableReinitialize
      validationSchema={organizationValidationSchema}
      onSubmit={async (values) => {
        if (!orgId) {
          createMutation.mutate(values, {
            onSuccess: () => {
              history.push(`/setup/team`);
            },
          });
        } else {
          updateMutation.mutate({ id: orgId, ...values });
        }
      }}
      {...rest}
    >
      {() => (
        <Form>
          <HeadedCard
            title={!orgId ? "Create Organization" : "Update Organization"}
            description=""
            footer={
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  disabled={
                    updateMutation.isLoading || createMutation.isLoading
                  }
                >
                  {(() => {
                    if (!orgId) {
                      return updateMutation.isLoading ? "Creating" : "Create";
                    }
                    return updateMutation.isLoading ? "Saving" : "Save";
                  })()}
                </button>
              </div>
            }
          >
            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Name"
                id="name"
                name="name"
                autoComplete="organization-name"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <FormikFormField
                label="Description"
                id="description"
                name="description"
                autoComplete="organization-description"
              />
            </div>
          </HeadedCard>
        </Form>
      )}
    </Formik>
  );
};
