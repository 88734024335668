import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  UserCircleIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { UserProfileForm } from "../forms/UserProfileForm";
import { UserSettingsForm } from "../forms/UserSettingsForm";
import { ChangePasswordForm } from "../forms/ChangePasswordForm";
import { ChangeEmailForm } from "../forms/ChangeEmailForm";
import { PageWithSubNav } from "./PageWithSubNav";

const subNavigation = [
  {
    name: "Your Profile",
    path: "/account/profile",
    icon: UserCircleIcon,
    component: UserProfileForm,
  },
  {
    name: "Account Settings",
    path: "/account/settings",
    icon: CogIcon,
    component: UserSettingsForm,
  },
  {
    name: "Change Email",
    path: "/account/change-email",
    icon: MailIcon,
    component: ChangeEmailForm,
  },
  {
    name: "Change Password",
    path: "/account/change-password",
    icon: KeyIcon,
    component: ChangePasswordForm,
  },
  {
    name: "Notifications",
    path: "/account/notifications",
    icon: BellIcon,
    component: UserProfileForm,
  },
  {
    name: "Delete account",
    path: "/account/delete",
    icon: CreditCardIcon,
    component: UserProfileForm,
  },
];

export const UserAccountPage = () => {
  return <PageWithSubNav routes={subNavigation} />;
};
