import {
  CogIcon,
  CreditCardIcon,
  UserCircleIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { useUser } from "@jonthompson/dailyscrum-shared";
import { OrgProfileForm } from "../forms/OrgProfileForm";
import { PageWithSubNav } from "./PageWithSubNav";

const subNavigation = [
  {
    name: "Profile",
    path: "/org/profile",
    icon: UserCircleIcon,
    component: OrgProfileForm,
  },
  {
    name: "Settings",
    path: "/org/settings",
    icon: CogIcon,
    component: OrgProfileForm,
  },
  {
    name: "Members",
    path: "/org/change-email",
    icon: MailIcon,
    component: OrgProfileForm,
  },
  {
    name: "Plan & Billing",
    path: "/org/billing",
    icon: CreditCardIcon,
    component: OrgProfileForm,
  },
  {
    name: "Delete Org",
    path: "/org/delete",
    icon: CreditCardIcon,
    component: OrgProfileForm,
  },
];

export const OrgSettingsPage = () => {
  const { data: user } = useUser();

  const routes = !user?.org
    ? [
        {
          name: "Create Organization",
          path: "/org",
          icon: UserCircleIcon,
          component: OrgProfileForm,
        },
      ]
    : subNavigation;

  if (!user?.org) {
  }

  return <PageWithSubNav routes={routes} />;
};
