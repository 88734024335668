import { ComponentType } from "react";
import { c8s } from "../../utils/c8s";
import { Link, Route, Switch, useLocation } from "react-router-dom";

export interface PageWithSubNavProps {
  routes: {
    name: string;
    path: string;
    component: ComponentType;
    icon: ComponentType<{ className?: string }>;
  }[];
}

export const PageWithSubNav = ({ routes }: PageWithSubNavProps) => {
  const location = useLocation();
  return (
    <>
      <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
              {routes.map(
                ({ name, path, component: Component, icon: Icon }) => (
                  <Link
                    key={path}
                    to={path}
                    className={c8s(
                      path === location.pathname
                        ? "bg-gray-50 text-orange-600 hover:bg-white"
                        : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                      "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                    )}
                    aria-current={
                      path === location.pathname ? "page" : undefined
                    }
                  >
                    <Icon
                      className={c8s(
                        path === location.pathname
                          ? "text-orange-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{name}</span>
                  </Link>
                )
              )}
            </nav>
          </aside>
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <Switch>
              {routes.map(({ path, component: Component }) => {
                return (
                  <Route key={path} path={path}>
                    <Component />
                  </Route>
                );
              })}
            </Switch>
          </div>
        </div>
      </main>
    </>
  );
};
