import { Link, useHistory } from "react-router-dom";
import { AuthPage } from "./AuthPage";
import { useCallback } from "react";
import { SignupForm, useSignupMutation } from "../forms/SignupForm";
import { Dialog, useDialog } from "../Dialog";
import { AlertBar } from "../AlertBar";

export const SignupPage = () => {
  const { mutation } = useSignupMutation();
  const history = useHistory();
  const dialog = useDialog();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          dialog.setIsOpen(true);
        },
      });
    },
    [mutation, dialog]
  );

  return (
    <>
      <AuthPage title="Sign up for an account">
        {mutation.isError && (
          <AlertBar title="Signup failed" variant="error" className="mb-4">
            {(mutation.error as Error).message || ""}
          </AlertBar>
        )}
        <SignupForm
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess}
          onSubmit={handleSubmit}
        />
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or</span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1">
            <div>
              <Link
                to="/login"
                className="btn-primary w-full flex justify-center"
              >
                Log in
              </Link>
            </div>
          </div>
        </div>
      </AuthPage>
      <Dialog
        title="Success!"
        description="Click the link in the email to verify your account"
        isClosable={false}
        actions={
          <button
            type="button"
            className="btn-primary inline-flex justify-center w-full"
            onClick={() => {
              dialog.setIsOpen(false);
              history.push("/login");
            }}
          >
            Close
          </button>
        }
        {...dialog}
      />
    </>
  );
};
