import { Formik, FormikConfig, FormikProps } from "formik";
import * as yup from "yup";
import { FormikFormField } from "../FormField";
import { AuthForm, AuthResultProps, AuthSubmitButton } from "./AuthForm";

type ResetPasswordFormFields = {
  password: string;
};

const initialValues: ResetPasswordFormFields = {
  password: "",
};

const validationSchema = yup.object().shape({
  password: yup.string().min(6).required(),
});

export interface ResetPasswordFormProps
  extends Omit<FormikConfig<ResetPasswordFormFields>, "initialValues">,
    AuthResultProps {}

export const ResetPasswordForm = ({
  isLoading,
  isSuccess,
  ...rest
}: ResetPasswordFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      {(props: FormikProps<ResetPasswordFormFields>) => (
        <AuthForm>
          <FormikFormField
            label="New password"
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            required
          />

          <AuthSubmitButton disabled={isLoading || isSuccess}>
            Save new password
          </AuthSubmitButton>
        </AuthForm>
      )}
    </Formik>
  );
};
