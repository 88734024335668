import { Link } from "react-router-dom";
import { Formik, FormikConfig, FormikProps } from "formik";
import * as yup from "yup";
import { FormikFormField } from "../FormField";
import { AuthForm, AuthResultProps, AuthSubmitButton } from "./AuthForm";

type LoginFormFields = {
  email: string;
  password: string;
};

const initialValues: LoginFormFields = {
  email: "",
  password: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export interface LoginFormProps
  extends Omit<FormikConfig<LoginFormFields>, "initialValues">,
    AuthResultProps {}

export const LoginForm = ({
  isLoading,
  isSuccess,
  ...rest
}: LoginFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      {(props: FormikProps<LoginFormFields>) => (
        <AuthForm>
          <FormikFormField
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
          />

          <FormikFormField
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label> */}
            </div>

            <div className="text-sm">
              <Link
                to="/forgot-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <AuthSubmitButton disabled={isLoading || isSuccess}>
            Log in
          </AuthSubmitButton>
        </AuthForm>
      )}
    </Formik>
  );
};
