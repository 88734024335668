import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuthListener, useUser } from "@jonthompson/dailyscrum-shared";

import { NavBar } from "./components/Navbar/Navbar";
import { LandingPage } from "./components/pages/LandingPage";
import { LoginPage } from "./components/pages/LoginPage";
import { ForgotPasswordPage } from "./components/pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./components/pages/ResetPasswordPage";
import { Footer } from "./components/Footer";
import { SignupPage } from "./components/pages/SignupPage";
import { HomePage } from "./components/pages/HomePage";
import { UserAccountPage } from "./components/pages/UserAccountPage";
import { TeamsPage } from "./components/pages/TeamsPage";
import { TeamSettingsPage } from "./components/pages/TeamSettingsPage";
import { OrgSettingsPage } from "./components/pages/OrgSettingsPage";
import { SetupPage } from "./components/pages/SetupPage";
import { fetchApi } from "./utils/fetchApi";

const RedirectRoute: React.FC<{
  path: string;
  to: string;
  when?: boolean | null;
}> = ({ path, to, when, children }) => {
  return (
    <Route path={path}>
      {when ? (
        <Redirect to={{ pathname: to, state: { referer: path } }} />
      ) : (
        children
      )}
    </Route>
  );
};

function App() {
  useAuthListener();
  const { data: user } = useUser();

  console.log({ user });

  useEffect(() => {
    fetchApi("/hello")
      .then((response) => {
        console.log("here", response);
      })
      .catch((error) => {
        console.error("here error", error);
      });
  }, [user]);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <NavBar />

        <Switch>
          <RedirectRoute path="/account" to="/login" when={!user}>
            <UserAccountPage />
          </RedirectRoute>

          <RedirectRoute path="/team/create" to="/login" when={!user}>
            <TeamSettingsPage />
          </RedirectRoute>

          <RedirectRoute path="/teams" to="/login" when={!user}>
            <TeamsPage />
          </RedirectRoute>

          <RedirectRoute path="/team/:id" to="/login" when={!user}>
            <HomePage />
          </RedirectRoute>

          <RedirectRoute path="/team/:id/settings" to="/login" when={!user}>
            <TeamSettingsPage />
          </RedirectRoute>

          <RedirectRoute path="/team/:id/edit-profile" to="/login" when={!user}>
            <TeamSettingsPage />
          </RedirectRoute>

          <RedirectRoute path="/org" to="/login" when={!user}>
            <OrgSettingsPage />
          </RedirectRoute>

          <RedirectRoute path="/setup" to="/login" when={!user}>
            <SetupPage />
          </RedirectRoute>

          <RedirectRoute path="/login" to="/" when={!!user}>
            <LoginPage />
          </RedirectRoute>
          <RedirectRoute path="/signup" to="/" when={!!user}>
            <SignupPage />
          </RedirectRoute>
          <RedirectRoute path="/forgot-password" to="/" when={!!user}>
            <ForgotPasswordPage />
          </RedirectRoute>
          <RedirectRoute path="/reset-password" to="/" when={!user}>
            <ResetPasswordPage />
          </RedirectRoute>

          <Route path="/">
            {(() => {
              if (user && !user?.org) {
                return <Redirect to="/setup" />;
              }
              return user ? <HomePage /> : <LandingPage />;
            })()}
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
