import { supabase } from "@jonthompson/dailyscrum-shared";
import { Formik, FormikConfig, FormikProps } from "formik";
import { useMutation } from "react-query";
import * as yup from "yup";
import { FormikFormField } from "../FormField";
import { AuthForm, AuthResultProps, AuthSubmitButton } from "./AuthForm";

type SignupFormFields = {
  email: string;
  password: string;
  inviteCode: string;
};

const initialValues: SignupFormFields = {
  email: "",
  password: "",
  inviteCode: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
  inviteCode: yup.string().required(),
});

export const useSignupMutation = () => {
  const mutation = useMutation(
    async ({ email, password, inviteCode }: SignupFormFields) => {
      if (inviteCode !== "not very secret") {
        throw new Error("Invalid invite code");
      }
      const { user, session, error } = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) {
        throw error;
      }
      return { user, session };
    }
  );

  return {
    mutation,
  };
};

export interface SignupFormProps
  extends Omit<FormikConfig<SignupFormFields>, "initialValues">,
    AuthResultProps {}

export const SignupForm = ({
  isLoading,
  isSuccess,
  ...rest
}: SignupFormProps) => {
  const signupButtonValue = (() => {
    if (isLoading) {
      return "Signing up...";
    }
    if (isSuccess) {
      return "Success!";
    }
    return "Sign up";
  })();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      {(props: FormikProps<SignupFormFields>) => (
        <AuthForm>
          <FormikFormField
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
          />

          <FormikFormField
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />

          <FormikFormField
            label="Invite code"
            id="inviteCode"
            name="inviteCode"
            type="text"
            required
          />

          <AuthSubmitButton disabled={isLoading || isSuccess}>
            {signupButtonValue}
          </AuthSubmitButton>
        </AuthForm>
      )}
    </Formik>
  );
};
