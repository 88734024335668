import { Menu, Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { useLogout, useUser } from "@jonthompson/dailyscrum-shared";
import { Fragment, useState } from "react";
import { c8s } from "../../utils/c8s";

const navigation = [
  { href: "/", name: "Today" },
  { href: "/tomorrow", name: "Tomorrow" },
  { href: "/teams", name: "Teams" },
  { href: "/org", name: "Organization" },
] as any[];

const userNavigation = [
  { name: "Your Profile", href: "/account/profile" },
  { name: "Settings", href: "/account/settings" },
  { name: "Log out", href: "/logout" },
];

const avatarInitials = (name: string) => {
  if (name) {
    const names = name.split(" ");
    const initials =
      names.length > 1 ? `${name[0]}${names[names.length - 1][0]}` : name[0];
    return initials;
  }
  return null;
};

export const NavBar = () => {
  const { data: user } = useUser();
  const logout = useLogout();
  const location = useLocation();
  const [mobileNavOpen, setMobileNavOpen] = useState(() => false);

  return (
    <Popover
      as="header"
      className={() =>
        c8s(
          mobileNavOpen ? "fixed inset-0 z-40 overflow-y-auto" : "",
          "bg-white shadow-sm lg:static lg:overflow-y-visible"
        )
      }
    >
      {() => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8 h-16">
              <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <h1 className="text-xl font-bold leading-7 text-gray-900 sm:truncate">
                      {process.env.REACT_APP_NAME}
                    </h1>
                  </Link>
                </div>
                {!mobileNavOpen && user && (
                  <div className="hidden md:ml-6 md:flex md:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {navigation.map((item) => {
                      return (
                        <Link
                          key={item.href}
                          to={item.href}
                          onClick={() => {
                            setMobileNavOpen(false);
                          }}
                          className={c8s(
                            location.pathname === item.href
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                  <div className="w-full"></div>
                </div>
              </div>
              <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                {/* Mobile menu button */}
                <button
                  className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500"
                  onClick={() => {
                    setMobileNavOpen((s) => !s);
                  }}
                >
                  <span className="sr-only">Open menu</span>
                  {mobileNavOpen ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
              <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                {!user && (
                  <>
                    <Link
                      to="/login"
                      onClick={() => {
                        setMobileNavOpen(false);
                      }}
                      className="py-2 px-4 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 mr-2"
                    >
                      <span>Log in</span>
                    </Link>
                    <Link
                      to="/signup"
                      className="btn-primary relative inline-flex items-center mr-2"
                      onClick={() => {
                        setMobileNavOpen(false);
                      }}
                    >
                      <span>Sign up</span>
                    </Link>
                  </>
                )}
                {/* <a
                  href="#foo"
                  className="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </a> */}

                {/* Profile dropdown */}
                {user && (
                  <Menu as="div" className="flex-shrink-0 relative ml-5">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                            <span className="sr-only">Open user menu</span>
                            {user?.details?.avatar_url ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={user?.details?.avatar_url}
                                alt=""
                              />
                            ) : (
                              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                <span className="text-xs font-medium leading-none text-white">
                                  {avatarInitials(
                                    user?.details?.display_name ||
                                      user?.details?.full_name ||
                                      "?"
                                  )}
                                </span>
                              </span>
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                          >
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => {
                                  if (item.href === "/logout") {
                                    return (
                                      <a
                                        key={item.href}
                                        href="/logout"
                                        className={c8s(
                                          active ? "bg-gray-100" : "",
                                          "block py-2 px-4 text-sm text-gray-700"
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setMobileNavOpen(false);
                                          logout();
                                        }}
                                      >
                                        Log out
                                      </a>
                                    );
                                  }
                                  return (
                                    <Link
                                      to={item.href}
                                      onClick={() => {
                                        setMobileNavOpen(false);
                                      }}
                                      className={c8s(
                                        active ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  );
                                }}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                )}
              </div>
            </div>
          </div>

          {mobileNavOpen && (
            <Popover.Panel
              as="nav"
              className="lg:hidden"
              aria-label="Global"
              static
            >
              <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                {user &&
                  navigation.map((item) => (
                    <Link
                      key={item.href}
                      to={item.href}
                      onClick={() => {
                        setMobileNavOpen(false);
                      }}
                      aria-current={
                        location.pathname === item.href ? "page" : undefined
                      }
                      className={c8s(
                        location.pathname === item.href
                          ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                          : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700",
                        "block rounded-md py-2 px-3 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                  {!user && (
                    <>
                      <Link
                        to="/login"
                        className="py-2 px-4 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 mr-2"
                        onClick={() => {
                          setMobileNavOpen(false);
                        }}
                      >
                        <span>Log in</span>
                      </Link>
                      <Link
                        to="/signup"
                        className="btn-primary relative inline-flex items-center mr-2"
                        onClick={() => {
                          setMobileNavOpen(false);
                        }}
                      >
                        <span>Sign up</span>
                      </Link>
                    </>
                  )}

                  {user && (
                    <>
                      <div className="flex-shrink-0">
                        {user?.details?.avatar_url ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user?.details?.avatar_url}
                            alt=""
                          />
                        ) : (
                          <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                            <span className="text-xs font-medium leading-none text-white">
                              {avatarInitials(
                                user?.details?.display_name ||
                                  user?.details?.full_name ||
                                  "?"
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">
                          {user?.details?.display_name ||
                            user?.details?.full_name}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {user?.email}
                        </div>
                      </div>
                      {/* <button
                      type="button"
                      className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                    </>
                  )}
                </div>
                {user && (
                  <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                    {userNavigation.map((item) => {
                      if (item.href === "/logout") {
                        return (
                          <a
                            key={item.href}
                            href="/logout"
                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                            onClick={(e) => {
                              e.preventDefault();
                              setMobileNavOpen(false);
                              logout();
                            }}
                          >
                            Log out
                          </a>
                        );
                      }
                      return (
                        <Link
                          key={item.href}
                          to={item.href}
                          onClick={() => {
                            setMobileNavOpen(false);
                          }}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

// export default function NavBar1() {
//   const { data: user } = useUser();
//   const logout = useLogout();
//   const history = useHistory();

//   return (
//     <Disclosure as="nav" className="bg-white shadow">
//       {({ open }) => (
//         <>
//           <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//             <div className="flex justify-between h-16">
//               <div className="flex">
//                 <div className="-ml-2 mr-2 flex items-center md:hidden">
//                   {/* Mobile menu button */}
//                   <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
//                     <span className="sr-only">Open main menu</span>
//                     {open ? (
//                       <XIcon className="block h-6 w-6" aria-hidden="true" />
//                     ) : (
//                       <MenuIcon className="block h-6 w-6" aria-hidden="true" />
//                     )}
//                   </Disclosure.Button>
//                 </div>
//                 <div className="flex-shrink-0 flex items-center">
//                   <Link to="/">
//                     <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
//                       {process.env.REACT_APP_NAME}
//                     </h1>
//                   </Link>
//                   {/* <img
//                     className="block lg:hidden h-8 w-auto"
//                     src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
//                     alt="Workflow"
//                   />
//                   <img
//                     className="hidden lg:block h-8 w-auto"
//                     src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
//                     alt="Workflow"
//                   /> */}
//                 </div>
//                 <div className="hidden md:ml-6 md:flex md:space-x-8">
//                   {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
//                   {navigation.map((item) => {
//                     return (
//                       <Link
//                         key={item.href}
//                         to={item.href}
//                         className={`${
//                           location.pathname === item.href
//                             ? "border-indigo-500 text-gray-900"
//                             : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                         } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
//                       >
//                         {item.name}
//                       </Link>
//                     );
//                   })}
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <div className="flex-shrink-0">
//                   {!user && (
//                     <>
//                       <Link
//                         to="/login"
//                         className="py-2 px-4 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 mr-2"
//                       >
//                         <span>Log in</span>
//                       </Link>
//                       <Link
//                         to="/signup"
//                         className="btn-primary relative inline-flex items-center mr-2"
//                       >
//                         <span>Sign up</span>
//                       </Link>
//                     </>
//                   )}
//                 </div>
//                 <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
//                   {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
//                     <span className="sr-only">View notifications</span>
//                     <BellIcon className="h-6 w-6" aria-hidden="true" />
//                   </button> */}

//                   {/* Profile dropdown */}
//                   {user && (
//                     <Menu as="div" className="ml-3 relative">
//                       {({ open }) => (
//                         <>
//                           <div>
//                             <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
//                               <span className="sr-only">Open user menu</span>
//                               {user?.details?.avatar_url ? (
//                                 <img
//                                   className="h-8 w-8 rounded-full"
//                                   src={user?.details?.avatar_url}
//                                   alt=""
//                                 />
//                               ) : (
//                                 <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
//                                   <span className="text-xs font-medium leading-none text-white">
//                                     {avatarInitials(
//                                       user?.details?.display_name ||
//                                         user?.details?.full_name ||
//                                         "?"
//                                     )}
//                                   </span>
//                                 </span>
//                               )}
//                             </Menu.Button>
//                           </div>
//                           <Transition
//                             show={open}
//                             as={Fragment}
//                             enter="transition ease-out duration-200"
//                             enterFrom="transform opacity-0 scale-95"
//                             enterTo="transform opacity-100 scale-100"
//                             leave="transition ease-in duration-75"
//                             leaveFrom="transform opacity-100 scale-100"
//                             leaveTo="transform opacity-0 scale-95"
//                           >
//                             <Menu.Items
//                               static
//                               className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
//                             >
//                               <Menu.Item>
//                                 {({ active }) => (
//                                   <Link
//                                     to="/account/profile"
//                                     className={c8s(
//                                       active ? "bg-gray-100" : "",
//                                       "block px-4 py-2 text-sm text-gray-700"
//                                     )}
//                                   >
//                                     Your Profile
//                                   </Link>
//                                 )}
//                               </Menu.Item>
//                               <Menu.Item>
//                                 {({ active }) => (
//                                   <Link
//                                     to="/account/settings"
//                                     className={c8s(
//                                       active ? "bg-gray-100" : "",
//                                       "block px-4 py-2 text-sm text-gray-700"
//                                     )}
//                                   >
//                                     Settings
//                                   </Link>
//                                 )}
//                               </Menu.Item>
//                               <Menu.Item>
//                                 {({ active }) => (
//                                   <Link
//                                     to="/account/billing"
//                                     className={c8s(
//                                       active ? "bg-gray-100" : "",
//                                       "block px-4 py-2 text-sm text-gray-700"
//                                     )}
//                                   >
//                                     Billing
//                                   </Link>
//                                 )}
//                               </Menu.Item>
//                               <Menu.Item>
//                                 {({ active }) => (
//                                   <a
//                                     href="/logout"
//                                     className={c8s(
//                                       active ? "bg-gray-100" : "",
//                                       "block px-4 py-2 text-sm text-gray-700"
//                                     )}
//                                     onClick={(e) => {
//                                       e.preventDefault();
//                                       logout();
//                                       history.push("/login");
//                                     }}
//                                   >
//                                     Log out
//                                   </a>
//                                 )}
//                               </Menu.Item>
//                             </Menu.Items>
//                           </Transition>
//                         </>
//                       )}
//                     </Menu>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Mobile Menu */}
//           <Disclosure.Panel className="md:hidden">
//             <div className="pt-2 pb-3 space-y-1">
//               {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
//               {navigation.map((item) => {
//                 return (
//                   <Link
//                     key={item.href}
//                     to={item.href}
//                     className={`${
//                       location.pathname === item.href
//                         ? "bg-indigo-50 border-indigo-500 text-indigo-700"
//                         : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
//                     } block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6`}
//                   >
//                     {item.name}
//                   </Link>
//                 );
//               })}
//             </div>
//             {user && (
//               <div className="pt-4 pb-3 border-t border-gray-200">
//                 <div className="flex items-center px-4 sm:px-6">
//                   <div className="flex-shrink-0">
//                     {user?.details?.avatar_url ? (
//                       <img
//                         className="h-10 w-10 rounded-full"
//                         src={user?.details?.avatar_url}
//                         alt=""
//                       />
//                     ) : (
//                       <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
//                         <span className="text-xs font-medium leading-none text-white">
//                           {avatarInitials(
//                             user?.details?.display_name ||
//                               user?.details?.full_name ||
//                               "?"
//                           )}
//                         </span>
//                       </span>
//                     )}
//                   </div>
//                   <div className="ml-3">
//                     <div className="text-base font-medium text-gray-800">
//                       {user?.details?.display_name || user?.details?.full_name}
//                     </div>
//                     <div className="text-sm font-medium text-gray-500">
//                       {user?.email}
//                     </div>
//                   </div>
//                   {/* <button className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
//                     <span className="sr-only">View notifications</span>
//                     <BellIcon className="h-6 w-6" aria-hidden="true" />
//                   </button> */}
//                 </div>
//                 <div className="mt-3 space-y-1">
//                   <Link
//                     to="/account/profile"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
//                   >
//                     Your Profile
//                   </Link>
//                   <Link
//                     to="/account/settings"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
//                   >
//                     Settings
//                   </Link>
//                   <Link
//                     to="/account/billing"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
//                   >
//                     Billing
//                   </Link>
//                   <a
//                     href="/logout"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       logout();
//                       history.push("/login");
//                     }}
//                   >
//                     Log out
//                   </a>
//                 </div>
//               </div>
//             )}
//           </Disclosure.Panel>
//         </>
//       )}
//     </Disclosure>
//   );
// }
