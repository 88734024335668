import { useMutation } from "react-query";
import { supabase } from "../supabase";

export interface ResetPasswordFields {
  password: string;
}

const getAccessToken = () => {
  const searchParams = new URLSearchParams(window.location.hash);
  if (searchParams.has("type") && searchParams.get("type") === "recovery") {
    return searchParams.get("access_token");
  }
  return false;
};

export const useResetPassword = () => {
  const accessToken = getAccessToken();

  const mutation = useMutation(async ({ password }: ResetPasswordFields) => {
    if (!accessToken) {
      throw new Error("No access token found");
    }
    const { data, error } = await supabase.auth.api.updateUser(accessToken, {
      password,
    });
    if (error) {
      throw error;
    }
    return { data };
  });

  return {
    mutation,
    accessToken,
    // validationSchema,
  };
};
