import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

export interface HeadedCardProps {
  footer?: React.ReactNode;
  title?: string;
  description?: string;
  id?: string;
  actionMenuItems?: React.ReactNode[];
  className?: string;
}

export const HeadedCard: React.FC<HeadedCardProps> = ({
  children,
  footer,
  title,
  description,
  id,
  actionMenuItems,
  className,
}) => {
  return (
    <section aria-labelledby={id && `${id}_heading`} className={className}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <div className="flex space-x-3">
              <div className="min-w-0 flex-1">
                <h2
                  id={id && `${id}_heading`}
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {title}
                </h2>
                <p className="mt-1 text-sm text-gray-500">{description}</p>
              </div>
              {actionMenuItems && (
                <div className="flex-shrink-0 self-center flex">
                  <Menu as="div" className="relative inline-block text-left">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <DotsVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="py-1">{actionMenuItems}</div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6 grid grid-cols-4 gap-6">{children}</div>
        </div>
        {footer && <div className="px-4 py-3 bg-gray-50 sm:px-6">{footer}</div>}
      </div>
    </section>
  );
};
