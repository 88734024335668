import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../supabase";

export const useLogout = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }
      return true;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        window.location.reload();
      },
    }
  );

  const logout = () => {
    mutation.mutate();
  };

  return logout;
};
