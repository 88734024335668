import { supabase, useUser } from "@jonthompson/dailyscrum-shared";
import { useQuery } from "react-query";
import { OrganizationSchema } from "../validationSchemas/orgSchema";
import {
  useCreateSingleByIdMutation,
  useUpdateSingleByIdMutation,
} from "./createUseMutations";

const TABLE_NAME = "organizations";

export const useCreateOrg = () => {
  return useCreateSingleByIdMutation<
    OrganizationSchema,
    Omit<OrganizationSchema, "id">
  >(TABLE_NAME);
};

export const useUpdateOrg = () =>
  useUpdateSingleByIdMutation<OrganizationSchema, OrganizationSchema>(
    TABLE_NAME
  );

export const useOrg = (id?: string) => {
  const query = useQuery<OrganizationSchema, Error, OrganizationSchema>(
    ["organization", id],
    async () => {
      const { data, error } = await supabase
        .from("organizations")
        .select("*")
        .match(
          id
            ? {
                "organizations.id": id,
              }
            : {}
        )
        .limit(1)
        .single();
      if (error) {
        throw error;
      }
      if (!data) {
        throw new Error("Org not found");
      }
      return data;
    },
    { enabled: !!id }
  );
  return query;
};

export const useOrgUsers = (id?: string) => {
  const { data: user } = useUser();

  const query = useQuery<OrganizationSchema[], Error, OrganizationSchema>(
    ["organization_users", id],
    async () => {
      const { data, error } = await supabase
        .from("organization_users, user_profiles")
        .select(
          `user_profiles.id,
          user_profiles.display_name,
          user_profiles.full_name,
          user_profiles.avatar_url,
        organization_users.role`
        )
        .match(
          id && user?.id
            ? {
                "user_profiles.id": user?.id,
                "organization_users.user_id": user?.id,
                "organization_users.organization_id": id,
              }
            : {}
        );
      if (error) {
        throw error;
      }
      if (!data) {
        return [];
      }
      return data;
    },
    { enabled: !!id && !!user }
  );
  return query;
};

export const useInviteOrgUser = () => {};

export const useRemoveOrgUser = () => {};

export const useEditOrgUserRole = () => {};

export const useCreateOrgSubSomain = () => {};
