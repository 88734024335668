import { User } from "@supabase/gotrue-js";
import { useQuery } from "react-query";
import { supabase } from "../supabase";

// const supabaseParallel = async (
//   ...requests: Promise<{ data: any; error: Error | null }>[]
// ) => {
//   const results = await Promise.all(requests);
//   for (let requestIndex = 0; requestIndex < results.length; requestIndex++) {
//     const { error } = results[requestIndex];
//     if (error) {
//       throw error;
//     }
//   }
//   return results;
// };

const initialData = ():
  | (User & { details: any; org: any | null; teams: any[] | null })
  | null => {
  const user = supabase.auth.user();
  if (!user) {
    return null;
  }
  return { ...user, details: {}, org: {}, teams: [] };
};

export const useUser = () => {
  const query = useQuery(
    "user",
    async () => {
      const user = supabase.auth.user();
      if (!user) {
        return null;
      }

      const [
        { data: settingsData, error: settingsError },
        { data: profileData, error: profileError },
        { data: orgData, error: orgError },
        { data: usersTeamsData, error: usersTeamsError },
      ] = await Promise.all([
        supabase
          .from("user_settings")
          .select("*")
          .eq("id", user.id)
          .limit(1)
          .single(),
        supabase
          .from("user_profiles")
          .select("*")
          .eq("id", user.id)
          .limit(1)
          .single(),
        supabase
          .from("organization_users")
          .select("organization_id, role")
          .eq("user_id", user.id)
          .limit(1),
        supabase
          .from("team_users")
          .select("team_id, role")
          .eq("user_id", user.id),
      ]);

      if (settingsError || profileError || orgError || usersTeamsError) {
        console.error(
          settingsError || profileError || orgError || usersTeamsError
        );
        throw settingsError || profileError || orgError || usersTeamsError;
      }
      return {
        ...user,
        details: { ...settingsData, ...profileData },
        org: orgData?.[0] && { id: orgData[0].organization_id, ...orgData[0] },
        teams: usersTeamsData,
      };
    },
    { initialData: initialData() }
  );

  return query;
};
