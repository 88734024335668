import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { supabase } from "../supabase";
import { useUser } from "./useUser";

export const useAuthListener = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();

  useEffect(() => {
    const currentSession = supabase.auth.session();
    if (currentSession && !user) {
      queryClient.invalidateQueries();
    }

    supabase.auth.onAuthStateChange(async () => {
      queryClient.invalidateQueries();
    });
  }, []);
};
