import { forwardRef } from "react";
import { c8s } from "../../utils/c8s";

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  hasError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ hasError, className, ...rest }: InputProps, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        className={c8s("input", hasError && "input-error", className)}
        aria-invalid={hasError ? true : undefined}
        {...rest}
      />
    );
  }
);
