import { createClient } from "@supabase/supabase-js";

if (!process.env.REACT_APP_SUPABASE_ANON_KEY) {
  throw new Error("No supabase anon key found");
}

export const supabase = createClient(
  "https://sb.dailyscrum.dev",
  process.env.REACT_APP_SUPABASE_ANON_KEY,
  { autoRefreshToken: true }
);
