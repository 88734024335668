import { useNotifications } from "../components/Notifications";

export const useOnErrorHandler = (title?: string) => {
  const { addNotification } = useNotifications();

  return (err: Error) => {
    addNotification({
      type: "failed",
      title: title || "Error",
      description: err.message || err.toString(),
    });
  };
};
