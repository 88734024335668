import { Formik, FormikConfig } from "formik";
import * as yup from "yup";
import { HeadedCard } from "../cards/HeadedCard";

type UserSettingsFormFields = {
  email: string;
  password: string;
};

const initialValues: UserSettingsFormFields = {
  email: "",
  password: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export interface UserSettingsFormProps
  extends Omit<
    FormikConfig<UserSettingsFormFields>,
    "initialValues" | "onSubmit"
  > {}

export const UserSettingsForm = ({ ...rest }: UserSettingsFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      {...rest}
    >
      {() => (
        <HeadedCard
          title="Account Settings"
          description="Update your billing information. Please note that updating your location could affect your tax rates."
          footer={
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                Save
              </button>
            </div>
          }
        >
          <div className="col-span-4 sm:col-span-2">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              Locale
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              autoComplete="cc-given-name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
          </div>

          <div className="col-span-4 sm:col-span-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              12/24 hour clock format
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              autoComplete="cc-family-name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
          </div>

          <div className="col-span-4 sm:col-span-2">
            <label
              htmlFor="email_address"
              className="block text-sm font-medium text-gray-700"
            >
              Date format
            </label>
            <input
              type="text"
              name="email_address"
              id="email_address"
              autoComplete="email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
          </div>
        </HeadedCard>
      )}
    </Formik>
  );
};
