import { supabase } from "@jonthompson/dailyscrum-shared";
import { useMutation, useQuery, useQueryClient } from "react-query";

export interface EntryItemT {
  id: string;
  user_id: string;
  daily_entry_id: string;
  team_id?: string;
  type: "yesterday" | "today" | "blockers";
  content: string;
  completed?: boolean;
}

export const useEntryItems = (
  { daily_entry_id, type }: Pick<EntryItemT, "type" | "daily_entry_id">,
  enabled?: boolean
) => {
  const query = useQuery<{}, Error, EntryItemT[]>(
    ["entryItems", daily_entry_id, type],
    async () => {
      const { data, error } = await supabase
        .from("entry_items")
        .select("*")
        .eq("daily_entry_id", daily_entry_id)
        .eq("type", type);
      if (error) {
        throw error;
      }
      return data;
    },
    { enabled }
  );

  return query;
};

export const useCreateEntryItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    EntryItemT,
    Error,
    Pick<
      EntryItemT,
      "daily_entry_id" | "type" | "content" | "user_id" | "team_id"
    >
  >(
    async ({ daily_entry_id, user_id, team_id, content, type }) => {
      const { data, error } = await supabase.from("entry_items").insert({
        daily_entry_id,
        user_id,
        team_id,
        content,
        type,
      });
      if (error) {
        throw error;
      }
      return data?.[0];
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["entryItems", result.daily_entry_id, result.type],
          (data) => {
            if (data && Array.isArray(data)) {
              return [...data, result];
            }
            return [result];
          }
        );
      },
    }
  );

  return mutation;
};

export const useUpdateEntryItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<EntryItemT, Error, EntryItemT>(
    async ({ id, ...rest }) => {
      const { data, error } = await supabase
        .from("entry_items")
        .update({
          ...rest,
        })
        .eq("id", id);
      if (error) {
        throw error;
      }
      return data?.[0];
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["entryItems", result.daily_entry_id, result.type],
          (data) => {
            if (data && Array.isArray(data)) {
              return data.map((d) =>
                d.id === result.id ? { ...d, ...result } : d
              );
            }
          }
        );
      },
    }
  );

  return mutation;
};

export const useDeleteEntryItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<EntryItemT, Error, Pick<EntryItemT, "id">>(
    async ({ id }) => {
      const { data, error } = await supabase
        .from("entry_items")
        .delete()
        .eq("id", id);
      if (error) {
        throw error;
      }
      return data?.[0];
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(
          ["entryItems", result.daily_entry_id, result.type],
          (data) => {
            if (data && Array.isArray(data)) {
              return data.filter((d) => d.id !== result.id);
            }
          }
        );
      },
    }
  );

  return mutation;
};
