import { useResetPassword } from "@jonthompson/dailyscrum-shared";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { AlertBar } from "../AlertBar";
import { ResetPasswordForm } from "../forms/ResetPasswordForm";
import { AuthPage } from "./AuthPage";

export const ResetPasswordPage = () => {
  const { mutation, accessToken } = useResetPassword();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          history.push("/login");
        },
      });
    },
    [history, mutation]
  );

  useEffect(() => {
    if (!accessToken) {
      // display an error?
    }
  }, [accessToken]);

  return (
    <AuthPage title="Reset your password">
      {mutation.isError && (
        <AlertBar title="Reset failed" variant="error" className="mb-4">
          {(mutation.error as Error).message || ""}
        </AlertBar>
      )}
      {accessToken ? (
        <ResetPasswordForm
          onSubmit={handleSubmit}
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess}
        />
      ) : (
        <AlertBar title="Error" variant="error">
          No token found. Please try again.
        </AlertBar>
      )}
    </AuthPage>
  );
};
