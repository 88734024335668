import { useForgotPassword } from "@jonthompson/dailyscrum-shared";
import { AuthPage } from "./AuthPage";
import { useCallback } from "react";
import { ForgotPasswordForm } from "../forms/ForgotPasswordForm";
import { Dialog, useDialog } from "../Dialog";
import { useHistory } from "react-router";

export const ForgotPasswordPage = () => {
  const { mutation } = useForgotPassword();
  const dialog = useDialog();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values) => {
      mutation.mutate(values, {
        onSettled: () => {
          dialog.setIsOpen(true);
        },
      });
    },
    [mutation, dialog]
  );

  return (
    <>
      <AuthPage title="Forgot your password?">
        <ForgotPasswordForm
          isLoading={mutation.isLoading}
          isSuccess={mutation.isSuccess}
          onSubmit={handleSubmit}
        />
      </AuthPage>
      <Dialog
        title="Done!"
        description="If an account exists with this email address it should shortly receive a link to reset the password"
        isClosable={false}
        actions={
          <button
            type="button"
            className="btn-primary inline-flex justify-center w-full"
            onClick={() => {
              dialog.setIsOpen(false);
              history.push("/login");
            }}
          >
            Login
          </button>
        }
        {...dialog}
      />
    </>
  );
};
