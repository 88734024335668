import { createContext, useCallback, useContext, useState } from "react";
import { Notification } from "../Notification/Notification";

export type NotificationData = {
  title: string;
  description?: string;
  type?: "success" | "failed";
  id: number;
  autoCloseTimeout?: NodeJS.Timeout;
};

export const NotificationsContext = createContext<{
  notifications: NotificationData[];
  addNotification: (
    d: Omit<NotificationData, "id" | "autoCloseTimeout">
  ) => void;
  removeNotification: (id: number) => void;
}>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
});

export const useNotifications = () => useContext(NotificationsContext);

let uid = 0;
const getUid = () => {
  uid = uid + 1;
  return uid;
};

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>(
    () => []
  );

  const removeNotification = useCallback((id) => {
    setNotifications((_n) =>
      _n.filter((nf) => {
        if (nf.id === id) {
          if (nf.autoCloseTimeout) {
            clearTimeout((nf.autoCloseTimeout as unknown) as number);
          }
        }
        return nf.id !== id;
      })
    );
  }, []);

  const addNotification = useCallback(
    (n: Omit<NotificationData, "id" | "autoCloseTimeout">) => {
      const id = getUid();

      let autoCloseTimeout: any;

      if (n.type === "success") {
        autoCloseTimeout = setTimeout(() => {
          removeNotification(id);
        }, 5000);
      }

      setNotifications((_n) => [{ ...n, id, autoCloseTimeout }, ..._n]);
      return id;
    },
    [removeNotification]
  );

  return (
    <NotificationsContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex flex-col items-end px-4 py-6 pointer-events-none sm:p-6"
      >
        {notifications.map((n) => {
          return (
            <Notification
              key={n.id}
              {...n}
              closeNotification={removeNotification}
            />
          );
        })}
      </div>
    </NotificationsContext.Provider>
  );
};

export const Notifications = () => {};
