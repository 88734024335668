import * as yup from "yup";

export type OrganizationSchema = {
  id: string;
  name: string;
  description?: string;
};

export const organizationValidationSchema = yup.object().shape({
  name: yup.string().max(100).required(),
  description: yup.string().nullable(),
});
